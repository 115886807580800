import React, {Component} from "react"
import axios from "axios"
import {GridCell, GridContainer} from "@grigorev/the-framework"

export default class Await extends Component {

    state = {
        seconds: 60,
    };

    intervalId = null;

    componentDidMount() {
        this.intervalId = setInterval(this.refresh, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        this.intervalId = null;
    }

    refresh = () => {
        this.setState(it => ({seconds: it.seconds - 1}));
    };

    reject = async () => {
        await axios.post("/auth/reject");
    };

    render() {
        const {seconds} = this.state;
        return <GridContainer rows="auto auto auto" gap={5}>
            <GridCell>Use Telegram bot to complete sign in.</GridCell>
            <GridCell>Token automatically rejects in {seconds} seconds.</GridCell>
            <button onClick={this.reject}>Reject now</button>
        </GridContainer>;
    }
}
