import React, {Component} from "react"
import axios from "axios"
import moment from "moment"
import {GridCell, GridContainer} from "@grigorev/the-framework"
import ErrorLabel from "./ErrorLabel"

export default class Status extends Component {

    state = {
        seconds: null,
    };

    intervalId = null;

    componentDidMount() {
        this.refresh();
        this.intervalId = setInterval(this.refresh, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        this.intervalId = null;
    }

    refresh = () => {
        const seconds = Math.floor((moment(this.props.valid) - moment()) / 1000);
        this.setState({seconds});
    };

    logout = async () => {
        await axios.post("/auth/logout");
    };

    render() {
        const {ip, login, host, admin, onAdmin, query, domains} = this.props;
        let {seconds} = this.state;
        const days = Math.floor(seconds / 3600 / 24);
        seconds = seconds % (3600 * 24);
        const hours = Math.floor(seconds / 3600);
        seconds = seconds % 3600;
        const minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        const forbiddenDomainMessage = (query.domain && !domains.includes(query.domain)) ? `Domain ${query.domain} forbidden. Contact admin to access it.` : null;
        const redirectUrl = (query.domain && domains.includes(query.domain)) ? `https://${query.domain}${query.env === "dev" ? ".dev" : ""}.emerge.su` : null;
        return <GridContainer rows={5 + admin + (query.domain !== null)} cols="auto 20px auto" gap={5}>
            <GridCell align="start">IP address:</GridCell><GridCell col={3}>{ip}</GridCell>
            <GridCell align="start">Login:</GridCell><GridCell col={3}>{login}</GridCell>
            <GridCell align="start">Host:</GridCell><GridCell col={3}>{host}</GridCell>
            <GridCell align="start">Expires:</GridCell><GridCell col={3}>{days}d {hours}h {minutes}m {seconds}s</GridCell>
            {admin && <GridCell width={3}><button onClick={onAdmin}>Admin area</button></GridCell>}
            {forbiddenDomainMessage && <ErrorLabel text={forbiddenDomainMessage}/>}
            {redirectUrl && <GridCell width={3} align="center" valign="center"><a href={redirectUrl}>Return to site</a></GridCell>}
            <GridCell width={3}><button onClick={this.logout}>Log out</button></GridCell>
        </GridContainer>;
    }
}
