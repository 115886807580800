import axios from "axios"
import {createStore, applyMiddleware, compose} from "redux"
import thunk from "redux-thunk"
import reducers from "redux/reducers"
import {enableBatching} from "redux-batched-actions"

// AXIOS
axios.defaults.baseURL = "/api";  // api url
// TODO uncomment when login is implemented
// axios.defaults.withCredentials = true;  // чтобы куки слались все такое (для авторизации)

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// REDUX
export const store = createStore(
    enableBatching(reducers),
    // мидлвар для исопльзования в браузере REDUX_DEVTOOLS_EXTENSION
    // FIXME: switch off on production
    composeEnhancer(applyMiddleware(thunk.withExtraArgument(axios)))
);
