import React, {Component} from "react"
import styles from "css/error.module.css"

class ErrorLabel extends Component {
    render() {
        return <span className={styles.label}>{this.props.text}</span>;
    }
}

export default ErrorLabel
