import React, {Component} from "react"
import axios from "axios"
import {GridCell, GridContainer} from "@grigorev/the-framework"
import Admin from "./Admin"
import Await from "./Await"
import LoginRegister from "./LoginRegister"
import Status from "./Status"
import styles from "css/main.module.css"

export default class App extends Component {

    state = {
        ip: null,
        status: null,
        valid: null,
        token: null,
        adminMode: false,
        query: {},
    }

    getIp = async () => {
        const {data} = await axios.get("/auth/ip");
        this.setState({ip: data.address});
    };

    refresh = async () => {
        const {data} = await axios.get("/auth/status");
        const adminMode = this.state.adminMode && data.status === "ACTIVE";
        this.setState({...data, adminMode});
    };

    intervalId = null;

    async componentDidMount() {
        this.getIp();
        this.intervalId = setInterval(this.refresh, 1000);
        this.refresh();
        const urlSearchParams = new URLSearchParams(window.location.search);
        const query = Object.fromEntries(urlSearchParams.entries());
        this.setState({query});
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        this.intervalId = null;
    }

    onAdminOn = () => {
        this.setState({adminMode: true});
    };

    onAdminOff = () => {
        this.setState({adminMode: false});
    };

    getComponent() {
        const {ip, status, valid, token, adminMode, query} = this.state;
        switch (status) {
            case null:
                return <div>Loading...</div>;
            case "ACTIVE":
                return adminMode ? <Admin onBack={this.onAdminOff}/> : <Status ip={ip} {...token} valid={valid} onAdmin={this.onAdminOn} query={query}/>;
            case "WAITING_CONFIRMATION":
                return <Await/>;
            case "INVALID":
                return <LoginRegister/>;
            default:
                return <div>Illegal token status.</div>;
        }
    }

    render() {
        return <GridContainer className={styles.container}>
            <GridCell align="center" valign="center" className={styles.cell}>
                {this.getComponent()}
            </GridCell>
        </GridContainer>;
    }
}
