import React, {Component} from "react"
import axios from "axios"
import {GridContainer, GridCell} from "@grigorev/the-framework"
import styles from "css/admin.module.css"

class Admin extends Component {

    state = {
        users: [],
        domains: [],
        logging: {
            enabled: false,
            timeout: null
        }
    }

    refreshStatusIntervalId = null;
    refreshEnabledIntervalId = null;

    async componentDidMount() {
        this.refreshStatusIntervalId = setInterval(this.refreshLoggingStatus, 1000);
        this.refreshEnabledIntervalId = setInterval(this.refreshEnabled, 20000);
        this.refresh();
        this.refreshLoggingStatus();
    }

    componentWillUnmount() {
        clearInterval(this.refreshStatusIntervalId);
        this.refreshStatusIntervalId = null;
        clearInterval(this.refreshEnabledIntervalId);
        this.refreshEnabledIntervalId = null;
    }

    refresh = async () => {
        const domains = axios.get("/admin/domains");
        const users = axios.get("/admin/users");
        this.setState({
            domains: (await domains).data,
            users: (await users).data,
        });
    };

    refreshLoggingStatus = async () => {
        const logging = (await axios.get("/admin/logging/status")).data;
        this.setState({logging});
    };

    refreshEnabled = async () => {
        if (this.state.logging.enabled) {
            await this.enableLogging();
        }
    };

    toggleLogging = current => async () => {
        await (current ? this.disableLogging : this.enableLogging)();
    };

    enableLogging = async () => {
        await axios.post("/admin/logging/enable");
    };

    disableLogging = async () => {
        await axios.post("/admin/logging/disable");
    };

    setEnabled = async (login, enabled) => {
        await axios.post("/admin/toggleUser", {login, enabled});
        await this.refresh();
    };

    setDomain = async (login, domain, enabled) => {
        const method = enabled ? "allowDomain" : "forbidDomain";
        await axios.post("/admin/" + method, {login, domain});
        await this.refresh();
    };

    render() {
        const {onBack} = this.props;
        const {users, domains, logging} = this.state;

        const rows = 6 + domains.length;
        const cols = Math.max(1, users.length);

        return <GridContainer rows={rows} cols={cols} flow="column" gap={5}>
            <GridCell width={cols}><button onClick={onBack}>Back</button></GridCell>
            <GridCell width={cols}><button onClick={this.refresh}>Refresh</button></GridCell>
            <GridCell width={cols}><button onClick={this.toggleLogging(logging.enabled)}>{logging.enabled ? "Disable" : "Enable"} logging {logging.enabled ? ("[" + Math.floor(logging.timeout / 1000) + "s]") : ""}</button></GridCell>
            <GridCell width={cols}/>
            {users.map(it => [
                <button key={it.login} className={it.enabled ? styles.selected : ""} onClick={() => this.setEnabled(it.login, !it.enabled)}>{it.login}</button>,
                <GridCell align="center" valign="center">|</GridCell>,
                ...domains.map(dm => <button key={it.login + "_" + dm} className={it.domains.includes(dm) ? styles.selected : ""} onClick={() => this.setDomain(it.login, dm, !it.domains.includes(dm))}>{dm}</button>)
            ])}
        </GridContainer>;
    }
}

export default Admin
