import React, {Suspense} from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import App from "components/App"
import {store} from "setup"

import "css/index.css"
import "@grigorev/the-framework/dist/index.css"

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback="loading">
            <App/>
        </Suspense>
    </Provider>,
    document.getElementById("root")
);
