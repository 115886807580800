import React, {Component} from "react"
import axios from "axios"
import _ from "lodash"
import {GridCell, GridContainer} from "@grigorev/the-framework"
import ErrorLabel from "./ErrorLabel"

const INTERVALS = {HOUR: 3600, DAY: 3600 * 24, WEEK: 3600 * 24 * 7, MONTH: 3600 * 24 * 30};

class Login extends Component {

    state = {
        login: "",
        host: "",
        selectedValidity: INTERVALS.MONTH,
        validity: INTERVALS.MONTH,
        errors: {},
        block: false,
    }

    componentDidMount() {
        const data = window.localStorage.getItem("login");
        if (data) {
            this.setState(JSON.parse(data));
        }
    }

    signIn = async e => {
        e.preventDefault();
        const {login, host, selectedValidity, validity} = this.state;
        const postData = {login, host, validity};
        const storeData = {...postData, selectedValidity};
        window.localStorage.setItem("login", JSON.stringify(storeData));
        try {
            await axios.post("/auth/login", postData);
            this.setState({errors: {}});
        } catch (error) {
            const {data} = error.response;
            if (data && data.errors) {
                const errors = _(data.errors).keyBy("field").mapValues("message").value();
                this.setState({errors});
            }
        }
    };

    onSelectedValidityChange = ({target: {value}}) => {
        const selectedValidity = +value;
        const validity = selectedValidity === 0 ? INTERVALS.HOUR : selectedValidity;
        this.setState({selectedValidity, validity});
    };

    onValidityChange = ({target: {value: validity}}) => {
        const data = {validity}
        const {errors} = this.state;
        if (!isFinite(validity)) {
            data.errors = {
                ...errors,
                validity: "Should be numeric.",
            };
            data.block = true;
        } else {
            data.errors = _(errors).pickBy((value, key) => key !== "validity").value();
            data.block = false;
        }
        this.setState(data);
    };

    render() {
        const {login, host, validity, selectedValidity, errors, block} = this.state;
        const errorLabels = _(errors).mapValues((message, field) => <ErrorLabel key={`error_${field}`} text={message}/>).value();
        const validityInput = selectedValidity === 0;
        const rowCount = 3 + (validityInput && 2) + Object.keys(errors).length;
        const rowConfig = new Array(rowCount).fill("auto").join(" ");
        return <form>
            <GridContainer rows={rowConfig} gap={5}>
                <input placeholder="Login..." autoCapitalize="none" value={login} onChange={e => this.setState({login: e.target.value})}/>
                {"login" in errors && errorLabels.login}
                <input placeholder="Host..." autoCapitalize="none" value={host} onChange={e => this.setState({host: e.target.value})}/>
                {"host" in errors && errorLabels.host}
                <select name="validity" value={selectedValidity} onChange={this.onSelectedValidityChange}>
                    <option value={INTERVALS.HOUR}>1 hour</option>
                    <option value={INTERVALS.DAY}>1 day</option>
                    <option value={INTERVALS.WEEK}>7 days</option>
                    <option value={INTERVALS.MONTH}>30 days</option>
                    <option value={0}>Custom</option>
                </select>
                {validityInput && [
                    <input key="validity_input" placeholder="Validity..." autoCapitalize="none" value={validity} onChange={this.onValidityChange}/>,
                    "validity" in errors && errorLabels.validity
                ]}
                <button type="submit" onClick={this.signIn} disabled={block}>Sign in</button>
            </GridContainer>
        </form>;
    }
}

class Register extends Component {

    state = {
        login: "",
        url: null,
        error: null,
    }

    signUp = async e => {
        e.preventDefault();
        try {
            const {data} = await axios.post("/auth/register", {login: this.state.login});
            this.setState({url: data, error: null});
        } catch (error) {
            const {data} = error.response;
            if (data.errors && data.errors[0]) {
                const {message} = data.errors[0];
                this.setState({error: message});
            }
        }
    };

    render() {
        const {login, url, error} = this.state;
        if (url) {
            return <GridContainer rows="auto auto" gap={5}>
                <GridCell>Follow this <a href={url} target="_blank" rel="noreferrer">link</a> to complete registration.</GridCell>
                <button onClick={() => this.setState({url: null})}>Cancel</button>
            </GridContainer>;
        } else {
            return <form>
                <GridContainer rows="auto auto auto" gap={5}>
                    <input placeholder="Login..." autoCapitalize="none" value={login} onChange={e => this.setState({login: e.target.value})}/>
                    {error && <ErrorLabel text={error}/>}
                    <button type="submit" onClick={this.signUp}>Register</button>
                </GridContainer>
            </form>;
        }
    }
}

export default class LoginRegister extends Component {
    render() {
        return <GridContainer rows="auto auto auto" gap={10}>
            <Login/>
            <GridCell align="center">or</GridCell>
            <Register/>
        </GridContainer>;
    }
}
